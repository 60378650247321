<template>
  <div class="businessRelease">
    <!-- <ReleaseFormDialog :dialog-data="dialogData" @editSuccess="editSuccess" /> -->
    <Breadcrumb :data="breData" :is$t="true" />
    <div class="container">
      <Title :title="$t('c246d22')" />
      <el-form
        ref="releaseForm"
        :model="releaseForm"
        :rules="rules"
        class="releaseForm"
        label-width="auto"
      >
        <el-form-item
          :label="autoPrefix('type') + ''"
          prop="type"
          class="commonRadio"
        >
          <el-radio-group v-model="releaseForm.type">
            <el-radio-button
              v-for="(value, key, index) of typeList"
              :key="index"
              :label="key"
            >
              {{ value }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="autoPrefix('consultingTypeId') + ''"
          prop="consultingTypeId"
          class="commonRadio"
        >
          <el-radio-group v-model="releaseForm.consultingTypeId">
            <el-radio-button
              v-for="(item, index) of dictionaryList.content"
              :key="index"
              :label="item.id"
            >
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="autoPrefix('title') + ''" prop="title">
          <el-input
            v-model="releaseForm.title"
            :placeholder="autoPrefix('title', 'pla')"
            maxlength="150"
            show-word-limit
          />
        </el-form-item>
        <el-form-item :label="autoPrefix('content')" prop="content">
          <el-input
            v-model="releaseForm.content"
            class="contentInput"
            type="textarea"
            maxlength="1000"
            :rows="7"
            :placeholder="autoPrefix('content', 'pla')"
            show-word-limit
          />
        </el-form-item>

        <el-form-item class="buttonCntainer">
          <el-button class="release" @click="submitForm('releaseForm')">
            {{ $t("d6421b8") }}
          </el-button>
          <el-button class="cancel" @click="cancelSubmitForm('releaseForm')">
            {{ $t("5b9fcb6") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Breadcrumb from "@/components/Breadcrumb";
import ReleaseFormDialog from "@/components/ReleaseFormDialog";
import Title from "@/components/Title";
import { CONSULTING_TYPE } from "@/utils/constants";
import { mapGetters, mapActions } from "vuex";
// const i18nPrefix = {
//   label: 'account.register.releaseForm.lable.',
//   pla: 'account.register.releaseForm.pla.',
// }
export default {
  name: "BusinessRelease",
  components: {
    ReleaseFormDialog,
    // ReleaseDemand,
    Breadcrumb,
    Title,
  },
  data() {
    return {
      // dialogData: {
      //   title: '编辑服务',
      //   type: 'ReleaseDesk',
      //   id: 21,
      //   dialogVisible: true,
      // },
      typeList: {
        BUSINESS_TYPE: "法律服务咨询",
        COMPANY_CONSULTING_TYPE: "企业入驻咨询",
        PLATFORM_CONSULTING_TYPE: "平台使用咨询",
        OTHER_CONSULTING_TYPE: "其他",
      },
      consultingTypeList: {
        1: "法律服务咨询",
        2: "企业入驻咨询",
        3: "平台使用咨询",
        4: "其他",
      },
      label: {
        type: "75cc586",
        consultingTypeId: "f2752de",
        title: "524b5c4",
        content: "6e491c1",
      },
      pla: {
        title: "db6077a",
        content: "4b83f94",
      },
      releaseForm: {
        type: "BUSINESS_TYPE",
        consultingTypeId: "",
        title: "",
        content: "",
      },
      breData: [
        {
          name: "e7fc30f",
          path: "/business/consult",
        },
        {
          name: "c246d22",
          path: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["dictionaryList"]),
    rules() {
      return {
        type: [{ required: true, message: this.$t("22d3ac0") }],
        consultingTypeId: [{ required: true, message: this.$t("6fd5fc3") }],
        title: [{ required: true, message: this.$t("db6077a") }],
        content: [{ required: true, message: this.$t("4b83f94") }],
      };
    },
  },
  watch: {
    "releaseForm.type"(newVal) {
      if (newVal) {
        this.getDictionaryList({
          type: this.releaseForm.type,
        });
      }
    },
  },
  mounted() {},
  created() {
    this.getDictionaryList({
      type: this.releaseForm.type,
    });
  },
  methods: {
    ...mapActions(["getDictionaryList"]),
    submitForm() {
      console.log("releaseForm", this.releaseForm);
      this.$refs.releaseForm.validate((valid) => {
        if (valid) {
          this.addConsulting();
        }
      });
    },
    addConsulting() {
      const obj = {
        BUSINESS_TYPE: "LAW_SERVICE",
        COMPANY_CONSULTING_TYPE: "COMPANY_ENTER",
        PLATFORM_CONSULTING_TYPE: "PLATFORM_USE",
        OTHER_CONSULTING_TYPE: "OTHER",
      };
      const data = {
        type: obj[this.releaseForm.type],
        consultingTypeId: this.releaseForm.consultingTypeId,
        title: this.releaseForm.title,
        content: this.releaseForm.content,
      };
      API.business.addConsulting(data).then(
        (res) => {
          this.handleRes(res);
        },
        (error) => {
          console.log("error", error);
        }
      );
    },
    handleRes(res) {
      this.$message({
        message: "发布成功",
        type: "success",
      });
      this.$router.push("/business/consult");
    },
    cancelSubmitForm() {
      this.$router.go(-1);
    },
    handleEditSuccess() {
      this.dialogData.dialogVisible = false;
    },
    autoPrefix(name, key = "label") {
      // const str = i18nPrefix[key] + name
      // return this.$t(str)
      let str = this[key][name];
      str = this.$t(str);
      if (key === "label") {
        str += "：";
      }
      return str;
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/styles/variables.less";
.businessRelease {
  width: @uni-width;
  margin: 0 auto;
  .container {
    width: 100%;
    padding: 0 30px;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
    .releaseForm {
      padding: 30px 0 40px;
      & /deep/.commonRadio {
        min-height: 74px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
      }
      & /deep/ .el-radio-button__inner {
        margin-right: 40px;
        border: none;
        padding: 6px 8px;
        border-radius: 2px;
      }
    }
  }
}
</style>
